.fui-page-footer-container {
  width: 100%;
  margin-top: 96px;
  box-sizing: border-box;
  .inner-container-complete {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .redirect-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .like-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .redirect-small-container {
    display: none;
  }
  @media (max-width: "960px") {
    .redirect-container {
      display: none;
    }
    .redirect-small-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 50px;
    }
    .like-icon-container {
      width: 100%;
    }
  }
}
