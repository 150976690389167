.paragraph-desc {
  font-size: 1rem;
  line-height: 1.565;
  letter-spacing: 0;
  font-weight: 400;
  margin-bottom: 16px;
  margin-top: 0;
 // color: #1a2027;
  word-break: break-word;
  &.dark-mode{
    color: rgba(255, 255, 255, 0.7);
  }
  .code {
    background-color: rgba(102, 178, 255, 0.15);
    color: #1a2027;
    padding: 0 5px;
    display: inline-block;
    font-size: 0.89rem;
    direction: ltr;
    font-weight: bolder;
    border-radius: 4px;
  }
}
