.paragraph-main {
  font-size: 1.125rem;
  line-height: 1.3333;
  letter-spacing: 0;
  font-weight: 400;
  margin-bottom: 16px;
  margin-top: 0;
  //color: #1A2027;
  .darkmode{
    color: rgba(255, 255, 255, 0.7);
  }
}
