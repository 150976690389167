/* .flex-container-group-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
} */
.flex-container-group-card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}
.flex-container-group-mosaic {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
  
