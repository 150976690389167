.right-menu-anchor-item {
  box-sizing: border-box;
  text-decoration: none;
  padding-left: 5px;
  width: 100%;
  //word-break: break-all;

  border-left: unset;
  color: #6f7e8c;

  &.selected,
  &:hover {
    border-left-width: 2px;
    border-left-style: solid;
    color: rgb(0, 114, 229);
    &.darkmode {
      color: rgb(102, 178, 255);
    }
  }
}
