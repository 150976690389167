.demo-controls-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    gap: 5px;
}

.demo-localization-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-height: 50vh;
    padding: 10px;
}

.dropzone-filemosaic-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1px;
}

.inputbutton-container {
    width: 100px;
}

.filecard-container {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

@media (max-width: 960px) {
    .demo-localization-container {
        flex-direction: column;
    }

    .dropzone-filemosaic-container {
        width: 100%;
    }

    .filecard-container {
        width: 100%;
        flex-direction: column;
    }
}