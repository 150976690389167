//@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap");

$main-color-light: #0a1929;
$main-color-dark: #0a1929;

$sec-color-light: #55b4f2;
$sec-color-dark: #0a1929;

$text-color-light: #042354;
$text-color-dark: rgba(255, 255, 255, 0.7);

$border-color-light: #0a1929;
$border-color-dark: #0a1929;

.files-uimain-container {
  transition: all 0.4s ease-in-out;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #0a1929;
  .filesui-nav {
    transition: all 0.4s ease-in-out;
    color: #042354;
    height: 64px;
    background-color: rgba(255, 255, 255, 0.559);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgb(234, 238, 243) 0px -1px 1px inset;
    width: 100%;
    font-weight: 300;

    .filesui-nav-container {
      box-sizing: border-box;
      padding: 0 10px;
      width: 100%;
      // background-color: aqua;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      max-width: 1280px;

      p {
        letter-spacing: 0em;
        line-height: 1.334;
        font-size: 1.6rem;
      }
      .filesui-nav-logo-container {
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.darkmode {
          border-radius: 8px;
          padding: 2px 0;
          background-color: rgba(255, 255, 255, 0.8);
        }
        .filesui-nav-logo {
          width: 45px;
          height: 45px;

          width: 45px;
        }
      }
      .left-part {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .filesui-nav-text-logo {
          font-size: 1.4rem;
          color: $main-color-light;
          font-weight: 300;
        }
      }
      .right-part {
        display: flex;
        justify-content: flex-start;
        //align-items: center;
        //justify-content: center;
        gap: 10px;
        //max-height: 500px;
      }
    }
  }
  .filesui-main {
    margin: auto;

    width: 100%;
    max-width: 1280px;

    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: row;

    align-items: flex-start;
    gap: 30px;
    box-sizing: border-box;
    padding: 0 28px;
    .fui-main-left {
      width: 48%;
      padding: 0 5px;
      padding-top: 20px;

      box-sizing: border-box;
      //background-color: bisque;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: calc(100vh - 64px);
      .filesui-main-logo-container {
        &.darkmode {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          background-color: rgba(255, 255, 255, 0.8);
          padding: 10px 0px;
        }
        .fui-logo-img {
          width: 156px;
          height: 156px;
        }
      }

      .fui-logo-text {
        font-size: 3.2rem;
        font-weight: 700;
        text-align: center;
        letter-spacing: 1px;
        margin: 20px 0;
        padding: 0;
        line-height: 3.5rem;
      }
      .fui-description {
        margin: 0;
        font-size: 1.5rem;
        //text-align: center;

        font-weight: 300;
      }
    }
    .fui-main-right {
      padding: 0 5px;
      //padding-top: 20px;

      box-sizing: border-box;
      //background-color: bisque;
      display: flex;
      flex-direction: column;
      //align-items: center;
      justify-content: center;
      width: 52%;
      min-height: calc(100vh - 64px);
      max-height: 1280px;
      // overflow: auto;
    }
  }
  .filesui-footer {
    min-height: 64px;
    background-color: #042354;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .files-ui-secondary-container {
    width: 100%;
    background-color: #f3f6f9;
    &.darkmode {
      background-color: #001e3c;
    }
    padding-bottom: 28px;
    .secondary-content {
      margin: auto;
      width: 100%;
      max-width: 1280px;
      //min-height: calc(100vh - 64px);

      .secondary-content-flex {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        gap: 30px;
        box-sizing: border-box;
        padding: 0 28px;
        flex-wrap: wrap;
        .secondary-item {
          flex-grow: 1;
          min-width: 30%;
        }
      }

      @media (max-width: 900px) {
        .secondary-right {
          width: 100%;
        }
        .secondary-left {
          width: 100%;
        }
      }
      .secondary-right {
        box-sizing: border-box;
        width: 50%;
        margin: auto;
      }
      .secondary-left {
        box-sizing: border-box;
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
  .gradient-span {
    font-weight: 700;
    background: linear-gradient(
      to right,
      #042354,
      #042354,
      $sec-color-light,
      #042354,
      #042354
    );
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
  &.darkmode {
    background-color: $main-color-dark;
    color: $text-color-dark;
    .filesui-nav {
      background-color: rgba(4, 35, 84, 0.559);
      color: $text-color-dark;
      box-shadow: rgba(234, 238, 243, 0.161) 0px -1px 1px inset;
    }

    .gradient-span {
      font-weight: 700;
      background: linear-gradient(to right, $sec-color-light, $sec-color-light);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    .filesui-nav {
      transition: all 0.4s ease-in-out;
      color: #042354;
      height: 64px;
      background-color: rgba(255, 255, 255, 0.559);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: rgb(234, 238, 243) 0px -1px 1px inset;
      width: 100%;
      font-weight: 300;

      .filesui-nav-container {
        p {
          letter-spacing: 0em;
          line-height: 1.334;
          font-size: 1rem;
        }
        .filesui-nav-logo-container {
          margin-right: 7px;

          .filesui-nav-logo {
            width: 35px;
            height: 35px;
          }
        }

        .left-part {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          p {
            font-size: 1.1rem;
            width: 150px;
          }
        }
      }
    }
    .filesui-main {
      flex-direction: column;
      gap: 5px;
      align-items: center;
      width: 100%;
      //padding: 15px;
      .fui-main-left,
      .fui-main-right {
        width: 100%;
        margin-bottom: 20px;
      }
      .fui-main-left {
        //padding-top: 5vh;
        min-height: auto;
        .fui-logo-img {
          width: 30vw;
          height: auto;
        }
        .fui-logo-text {
          font-size: 2.8em;
          text-align: center;
        }
        .fui-description {
          //font-size: 1.2em;
          text-align: left;
        }
      }
    }
    .filesui-footer {
      font-size: smaller;
    }
  }
}

.rc-hl-code-line {
  font-size: 16px !important;
  line-height: 19px !important;
}
