.type-highlight {
  color: rgb(147, 41, 129);
  //color: #ef7ed1;

  font-size: 0.9125rem;
  line-height: 1.6;
 // word-break: break-all;
  padding: 0px 5px;
  display: inline-block;
  &.np{
    padding: 0;
  }
  &.darkmode{
    color: #ffb6ec;
  } 
}
