.fui-logo-img-getting-started {
  width: 150px;
  height: 150px;

  @media screen and (max-width: 900px) {
    width: 30vw;
    height: auto;
  }
}
.clipboard-container {
  background-color: #1e1e1e;
  border-radius: 8px;
  position: relative;
  display: flex;
  padding: 12px 7px;
  width: calc(100% - 20px);
  flex-direction: row;
  align-items: center;
  font-size: 0.9em;
  margin-bottom: 15px;
  justify-content: space-between;
  .floating-copy-button {
    box-sizing: border-box;
    background-color: gray;
    padding: 6px 5px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;

    &:hover {
      border: 1px wheat solid;
      padding: 5px 4px;
    }
    &.copied {
      border: 1px solid greenyellow;
      padding: 5px 4px;
    }
  }
}
